import { motion } from "framer-motion"

const R1L1 = "This game is pinball and pong.";
const R1L2 = "It is played against an AI.";
const R1L3 = "For each game won, you get rewards TIL (ERC20 token) that allow you to buy skins to improve your paddle or the playing field.";
const R1L4 = "The game is currently in alpha version, only the training mode is active.";

const sentence = {
    hidden: {opacity:1},
    visible:{
        opacity:1,
        transition: {
            delay: 0.5,
            staggerChildren: 0.08,
        }
    }
}

const letter = {
    hidden: {opacity:0, y:50},
    visible: {opacity:1, y:0},
}



export default function Faq() {

  return (
    <div className='faq'>
        <motion.h3
            className="load-screen--message"
            variants={sentence}
            initial="hidden"
            animate="visible">
            <p>
            {R1L1.split("").map((char, index) => {
                return(
                    <motion.span key={char+"-" +index} variants={letter}>{char}</motion.span>
                )
            })}
            </p>
            <p>
            {R1L2.split("").map((char, index) => {
                return(
                    <motion.span key={char+"-" +index} variants={letter}>{char}</motion.span>
                )
            })}
            </p>
            <p>
            {R1L3.split("").map((char, index) => {
                return(
                    <motion.span key={char+"-" +index} variants={letter}>{char}</motion.span>
                )
            })}
            </p>
            <p>
            {R1L4.split("").map((char, index) => {
                return(
                    <motion.span key={char+"-" +index} variants={letter}>{char}</motion.span>
                )
            })}
            </p>
        </motion.h3>
    </div>
  );
}