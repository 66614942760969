import {  useBox } from "@react-three/cannon";
import { RoundedBox } from '@react-three/drei';


export default function Barre({ args = [22, 1, 1], color, ...props }) {
    
    const [ref] = useBox(() => ({ args, ...props }));
    return (
      <mesh ref={ref}>
      <RoundedBox radius={0.25} smoothness={10} args={args} >
      <meshStandardMaterial color={color}/>
      </RoundedBox>
      </mesh>
    );
}