export default function Score(props) {

  return (
    
    <div className='score'>
      <div className='move'>
        <div className="d-flex button magenta">
          <div className="only-full">ROBOT</div>
          <div className='only-full  mx-auto fs-3'>{props.countAI}</div>
          <div className='only-reduce fw-bold  mx-auto fs-2'>{props.countAI}</div>
        </div>
        <div className="d-flex button magenta">
          <div className="only-full">PLAYER</div>
          <div className='only-full mx-auto fs-3'>{props.countPlayer}</div>
          <div className='only-reduce fw-bold mx-auto fs-2'>{props.countPlayer}</div>
        </div>
      </div>
    </div>

  );
}