import { useEffect } from 'react';
import { useFrame } from "@react-three/fiber";
import { useSphere, useBox } from "@react-three/cannon";
import { useSnapshot } from "valtio";

import { YELLOW, MAGENTA, CYAN, state } from '../store';

import { randomIntBetween } from '../tools';

function randomStart(_apiBall) {
  const randomInt = randomIntBetween (1, 2);
  (randomInt === 1) ?  _apiBall.velocity.set(2, 15, 0) : _apiBall.velocity.set(-2, 15, 0)
}

function serviceStart(_apiBall) {
    if (state.service === "AI"){
      (state.sceneColor === YELLOW) ?  _apiBall.velocity.set(18,12,0) :  _apiBall.velocity.set(12,8,0);
    }
    if (state.service === "PLAYER"){
      (state.sceneColor === YELLOW) ?  _apiBall.velocity.set(-18,12,0) :  _apiBall.velocity.set(-12,8,0);
    }
  }

function game(_apiBall){
    _apiBall.velocity.set(0,0,0);
    _apiBall.position.set(0,0,0);
    if ((state.countAI > 0) && (state.countPlayer > 0)){
      const randomIntScene = randomIntBetween (1, 3);
      if (randomIntScene === 1){
        state.api.setSceneColor(MAGENTA); 
        state.api.setDecrement(35);
      };
      if (randomIntScene === 2){
        state.api.setSceneColor(YELLOW);
        state.api.setDecrement(20);
      };
      if (randomIntScene === 3){
        state.api.setSceneColor(CYAN);
        state.api.setDecrement(20);
      };
      serviceStart(_apiBall);
    } else {
      state.api.toEnd();
      (state.countAI > 0) ? state.api.win("AI") : state.api.win("PLAYER");
    }
}

export default function Ball() {

  const { start, api  } = useSnapshot(state);
  const [ball, apiBall] = useSphere(() => ({ mass: 1, args: [0.5], position: [0, 0, 0]}));

  //   PLAYER

  useBox(() => ({position: [0, -15, 0], args: [40, 1, 1], onCollide: () => {
      api.decrementPlayer();
      api.setService("PLAYER");
      game(apiBall);
    }
  }));

  useBox(() => ({position: [13, -10, 0], args: [1, 15, 1], onCollide: () => {
      api.decrementPlayer();
      api.setService("PLAYER");
      game(apiBall);
    }
  }));

  useBox(() => ({ position: [-13, -10, 0], args: [1, 15, 1], onCollide: () => {
      api.decrementPlayer();
      api.setService("PLAYER");
      game(apiBall);
    }
  }));
  

  // AI

  useBox(() => ({position: [- 13, 10, 0], args: [1, 15, 1], onCollide: () => {
      api.decrementAI();
      api.setService("AI");
      game(apiBall);
    }
  }));

  useBox(() => ({position: [13, 10, 0], args: [1, 15, 1], onCollide: () => {
      api.decrementAI();
      api.setService("AI");
      game(apiBall);
    }
  }));

  useFrame(() => {
    if (start) {
      randomStart(apiBall);
      api.updateStart(false);
    }
  });

  useEffect(() => apiBall.position.subscribe(v => api.setBallPosition(v)));

  return (
    <mesh ref={ball}>
      <sphereGeometry args={[0.5, 64, 64]} />
      <meshStandardMaterial color="white" />
    </mesh>
  )
}