import logo from '../assets/logo.png';

export default function Header(props){

    return(
        <div className='only-reduce header'>
            <button
                onClick={() => props.action('game')} 
                className='button-header magenta'>
                <img
                    src={logo}
                    width="25"
                    height="25"
                    alt="logo"
                    className='me-2'
                />
                TILTPONG<small className='text-lowercase'> alpha</small>
            </button>
        </div>

    );
}