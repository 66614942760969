import { useState } from 'react';
import Game from './Game';
import Faq from './Faq';
import Launch from './components/Launch';
import Header from './components/Header';

//import socketIO from 'socket.io-client';
//const socket = socketIO.connect('https://localhost:4000');

export default function App() {

  const [view, setView] = useState('game');

  return (
    <>
      <Header action={setView}/>
      { (view === 'game') ? <Game setView={setView}/> : <></>}
      { (view === 'faq') ? <Faq/> : <></>}
      <Launch action={setView}/>
    </>
  );
}