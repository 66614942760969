import { useRef } from 'react';
import { useFrame } from "@react-three/fiber";
import Text from './Text';

export default function GameOver({winner, color}) {
    const ref = useRef();
    useFrame(({ clock }) => (ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z = Math.sin(clock.getElapsedTime()) * 0.3));
    return (
      <group ref={ref}>
        <Text color={color} hAlign="center" position={[0, 5, 0]} children="GAME" />
        <Text color={color} hAlign="center" position={[0, 2, 0]} children="OVER" />
        <Text color={color} hAlign="center" position={[0, -1, 0]} children={winner.toString()}/>
        <Text color={color} hAlign="center" position={[0, -4, 0]} children="WON"/>
      </group>
    )
}