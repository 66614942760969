import { useBox} from "@react-three/cannon";
import { useFrame } from "@react-three/fiber";
import { RoundedBox } from '@react-three/drei';

export default function Enemy({ args = [1, 2, 1], color, ...props }) {

    const [ref, api] = useBox(() => ({ args, ...props }));
    useFrame(({clock}) => { api.rotation.set(0, 0, Math.sin(clock.getElapsedTime())) });
    return (
      <mesh ref={ref}>
      <RoundedBox radius={0.25} smoothness={10} args={args} >
        <meshStandardMaterial color={color}/>
      </RoundedBox>
      </mesh>
    );
}