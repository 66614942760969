import { useFrame } from "@react-three/fiber";
import { useBox } from "@react-three/cannon";
import { useSnapshot } from "valtio";
import { RoundedBox } from '@react-three/drei';

import { state } from '../store';

export default function PaddleAI() {

  const { scenePaddle, api, sceneColor, ballPosition  } = useSnapshot(state);
  const [paddleAI, apiPaddleAI] = useBox(() => ({ args: [4, 1, 1], onCollide: () => api.pongAI() }));

  useFrame(() => {
    apiPaddleAI.position.set(ballPosition[0] , 13, 0);
  });

  return (
    <mesh ref={paddleAI}>
      <RoundedBox radius={0.25} smoothness={10} args={scenePaddle} >
        <meshStandardMaterial color={sceneColor}/>
      </RoundedBox>
    </mesh>
  )
}