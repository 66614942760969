import Button from 'react-bootstrap/Button';

import { FaChevronRight, FaChevronUp, FaWallet, FaQuestion } from "react-icons/fa";

export default function PadRight(props) {

  return (
    <div className='only-reduce pad-right'>
        <div className='d-flex'>
        <Button
            onClick={() => props.setView('faq')}
            className="button magenta"
            variant="outline">
            <FaQuestion size={25}/>
        </Button>
        <Button
            onClick={props.rotation}
            className="button magenta"
            variant="outline">
            <FaChevronUp size={30}/>
        </Button>
        </div>
        <div className='d-flex'>
            <div
                className="button cyan text-center"
                >
                <FaWallet size={30}/>
            </div>
            <Button
                onClick={props.position}
                className="button magenta"
                variant="outline">
                <FaChevronRight size={30}/>
            </Button>
        </div>
    </div>
  );
}