import { proxy } from "valtio";

export const YELLOW = "#8B008B";
export const MAGENTA = "#ff3beb";
export const CYAN = "#00ffc3";

export const state = proxy({
    ballPosition: [0,0,0],
    training: false,
    start : false,
    service : "",
    end : false,
    winner: "",
    countAI: 100,
    countPlayer: 100,
    sceneColor: MAGENTA,
    scenePaddle: [4, 1, 1],
    decrement: 35,
    api: {
      setBallPosition: (_vector) => (state.ballPosition = Array.from(_vector)),
      setTraining: (_bool) => (state.training = _bool),
      setService: (_player) => (state.service = _player), 
      setSceneColor: (_color) => (state.sceneColor = _color),
      toEnd : () => (state.end = true),
      updateStart: (_bool) => (state.start = _bool),
      win : (_winner) => (state.winner = _winner),
      pongAI: () => (state.countAI = state.countAI + 1),
      pongPlayer: () => (state.countPlayer = state.countPlayer + 1),
      setDecrement: (_decrement) => (state.decrement = _decrement),
      decrementAI : () => ((state.countAI - state.decrement > 0 ) ? state.countAI = state.countAI - state.decrement : state.countAI = 0),
      decrementPlayer : () =>  ((state.countPlayer - state.decrement > 0 ) ? state.countPlayer = state.countPlayer - state.decrement : state.countPlayer = 0),
      reset: () => {
        state.countAI = 100;
        state.countPlayer = 100;
        state.start = true;
        state.end = false;
        state.service = "";
        state.sceneColor = MAGENTA;
        state.ballPosition = [0,0,0];
      }
    },
});