import Button from 'react-bootstrap/Button';

import { FaDiscord, FaHome, FaShieldAlt, FaStore, FaQuestion, FaWallet, FaBitcoin } from "react-icons/fa";

export default function Launch(props) {

  return (
    
    <div className='only-full launch'>
     
      <div className='move'>
        <Button className="button magenta" variant="outline" onClick={() => props.action('game')}><FaHome className='me-2' size={30}/>TILTPONG</Button>
        <div className="button cyan text-center" variant="outline"><FaShieldAlt className='me-2' size={30}/>Skins</div>
        <div className="button cyan text-center" variant="outline"><FaStore className='me-2' size={30}/>Market</div>
        <div className="button cyan text-center"variant="outline"><FaBitcoin className='me-2' size={30}/>TIL</div>
        <Button className="button magenta" variant="outline" onClick={() => props.action('faq')}><FaQuestion className='me-2' size={25}/>How to</Button>
        <Button className="button magenta" variant="outline" href="https://discord.gg/DjMDWFxjFQ"><FaDiscord className='me-2' size={30}/>Discord</Button>
        <div className="button cyan text-center" variant="outline"><FaWallet className='me-2' size={25}/>Wallet</div>
      </div>
  
    </div>

  );
}

/*
    const start = () => {
        //props.api.setTraining(false);
        //props.api.reset();
        props.socket.emit('newPlayer', {
          name: playerLeft,
          socketID: props.socket.id,
        });
    }
*/