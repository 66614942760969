import Button from 'react-bootstrap/Button';

import { FaSync, FaTableTennis } from "react-icons/fa";

export default function Play(props) {

  const training = () => {
      props.api.setTraining(true);
      props.api.reset();
  }

  return (
    <div className='only-full play'>
     
      <div className='move'>
        <Button className="button magenta" variant="outline" onClick={training}><FaSync className='me-2' size={30}/>Training</Button>
        <div className="button cyan text-center" variant="outline"><FaTableTennis className='me-2' size={30}/>Play</div>
      </div>
  
    </div>
  );
}

/*
    const start = () => {
        //props.api.setTraining(false);
        //props.api.reset();
        props.socket.emit('newPlayer', {
          name: playerLeft,
          socketID: props.socket.id,
        });
    }
*/