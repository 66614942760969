import Button from 'react-bootstrap/Button';

import { FaChevronLeft, FaChevronDown, FaSync, FaTableTennis } from "react-icons/fa";

export default function PadLeft(props) {

  const training = () => {
    props.api.setTraining(true);
    props.api.reset();
  }

  return (
    <div className='only-reduce pad-left'>
      <div className='d-flex'>
      <Button
        onClick={props.rotation}
        className="button magenta"
        variant="outline">
          <FaChevronDown size={30}/>
      </Button>
      <Button
        onClick={training}
        className="button magenta"
        variant="outline">
          <FaSync size={30}/>
      </Button>
      </div>

      <div className='d-flex'>
      <Button
        onClick={props.position}
        className="button magenta"
        variant="outline">
          <FaChevronLeft size={30}/>
      </Button>
      <div
        className="button cyan text-center"
        >
          <FaTableTennis size={30}/>
      </div>
      </div>
    </div>
  );
}