import Plane from './Plane';
import Barre from './Barre';

export default function Scene(props) {

  return (
    <>
        <Plane color={props.color} position={[9, 5, 0]}  rotation={[0,0,-0.1]}/>
        <Plane color={props.color} position={[-9, 5, 0]}  rotation={[0,0,0.1]}/>
        <Plane color={props.color} position={[9,-5, 0]}  rotation={[0,0,0.1]}/>
        <Plane color={props.color} position={[-9, -5, 0]}  rotation={[0,0,-0.1]}/>
        <Barre color={props.color} position={[0,-15,0]}  />
        <Barre color={props.color} position={[0,15,0]}  />
    </>
  )
}