import {Suspense, useState } from 'react';
import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/cannon";
import { useSnapshot } from "valtio";
import { Stars } from '@react-three/drei';

import { CYAN, state } from './store';

import Scene from './components/Scene';
import Enemy from './components/Enemy';
import GameOver from './components/GameOver';
import Play from './components/Play';
import Score from './components/Score';
import PadLeft from './components/PadLeft';
import PadRight from './components/PadRight';
import PaddlePlayer from './components/PaddlePlayer';
import PaddleAI from './components/PaddleAI';
import Ball from './components/Ball';


export default function Game(props) {

  const [paddlePosition, setPaddlePosition] = useState(0);
  const [paddleRotation, setPaddleRotation] = useState(0);
  const { end, winner, sceneColor, api, countPlayer, countAI } = useSnapshot(state);

  const goToLeft = () => setPaddlePosition(paddlePosition - 1);
  const goToBottom = () => setPaddleRotation(paddleRotation - 1);
  const goToRight = () => setPaddlePosition(paddlePosition + 1);
  const goToUp = () => setPaddleRotation(paddleRotation + 1);

  return (
    <>
    <div className='game'>
      <Canvas camera={{ position: [0, 0, 25] }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 5]} />
        <pointLight position={[-10, -10, -5]} />
        <Suspense fallback={null}>
          {(end) ? <GameOver color={sceneColor} winner={winner}/> : <></>}
          <Stars radius={100} depth={10} count={5000} factor={4} saturation={0} fade speed={2} />
        </Suspense>
        <Physics gravity={[0, 0, 0]} defaultContactMaterial={{ restitution: 1.05 }}>
          <Scene color={sceneColor}/>
          { ((sceneColor === CYAN) && (!end)) ? <Enemy color={sceneColor} position={[0, 4, 0]}/> : <></>}
          { ((sceneColor === CYAN) && (!end)) ? <Enemy color={sceneColor} position={[0, -4, 0]}/> : <></>}
          {(end) ? <></> : <Enemy color={sceneColor} position={[4, 0, 0]}/>}
          {(end) ? <></> : <Enemy color={sceneColor} position={[-4, 0, 0]}/>}
          {(end) ? <></> : <Ball />}
          <PaddlePlayer position={paddlePosition} rotation={paddleRotation}/>
          <PaddleAI/>
        </Physics>
      </Canvas>
    </div>
    <Score countPlayer={countPlayer} countAI={countAI}/>
    <Play api={api}/>
    <PadLeft position={goToLeft} rotation={goToBottom} api={api}/>
    <PadRight position={goToRight} rotation={goToUp} setView={props.setView}/>
    </>
  );
}