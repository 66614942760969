import { useFrame } from "@react-three/fiber";
import { useBox } from "@react-three/cannon";
import { useSnapshot } from "valtio";
import { RoundedBox } from '@react-three/drei';

import { state } from '../store';


export default function PaddlePlayer(props) {

  const { scenePaddle, api, sceneColor } = useSnapshot(state);
  const [paddlePlayer, apiPaddlePlayer] = useBox(() => ({ args: [4, 1, 1], onCollide: () => api.pongPlayer() }));

  useFrame((state) => {
    apiPaddlePlayer.position.set( (state.mouse.x * state.viewport.width) / 2 + props.position,  -13 , 0);
    apiPaddlePlayer.rotation.set(0, 0, ((((state.mouse.y + props.rotation)* state.viewport.height - 8) * Math.PI)) / 30);
  });

  return (
    <mesh ref={paddlePlayer}>
      <RoundedBox radius={0.25} smoothness={10} args={scenePaddle} >
        <meshStandardMaterial color={sceneColor}/>
      </RoundedBox>
    </mesh>
  )
}